import React from 'react';
import AdvTrainia from './advTrainia';
import Battledia from './battledia';
import Mag from './mag';
import Ryuker from './ryuker';
import TrainingTrainia from './trainingTrainia';

export default function Other(){return(<>
<AdvTrainia/>
<Battledia/>
<Mag/>
<Ryuker/>
<TrainingTrainia/>
</>)};